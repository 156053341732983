//these styles are added because lforms npm package is overiding custom styles 
//if you remove lforms disable these styles
span {
    font-size: 14px !important;
}
h1 {
    font-size: '2rem';
}
h2 {
    font-size: '1.75rem';
}
h3 {
    font-size: '1.5rem';
}
h4 {
    font-size: '1.25rem';
}
h5 {
    font-size: '1rem';
}
h6 {
    font-size: '0.875rem';
}

.material-icons{
    font-size: 20px !important;
}
.MuiMenuItem-root{
    font-size: 14px;
}
.MuiSelect-icon{
    font-size: 1.9rem !important;
}
.breadcrumb{
    background-color: #fafafa !important;
}
p{
    font-size: 16px;
}